<template>
  <div>
    <div
      class="modal fade"
      id="SeeResultModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      v-bind="$attrs"
      ref="resultModal"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content" id="resultModalContent">
          <!-- Modal Content -->
          <div>
            <!-- Loader -->
            <div v-if="isLeadLoading" style="min-height: 500px">
              <LoaderComp />
            </div>

            <div v-else class="p-4 text-left">
              <div v-if="LeadDetail">
                <div class="d-flex align-items-center justify-content-end">
                  <p @click="CloseModal" style="cursor: pointer">
                    <i class="fa fa-times" aria-hidden="true"></i>
                  </p>
                </div>
                <div class="row text-left">
                  <div class="col-12">
                    <p
                      class=""
                      style="font-weight: 600; font-size: 24px; color: #292929"
                    >
                      {{ LeadDetail.name }}
                    </p>
                  </div>
                  <div class="col-12" v-if="LeadDetail.phoneNumber">
                    <p
                      class=""
                      style="font-weight: 600; font-size: 16px; color: #4d4950"
                    >
                      Phone Number:
                      <span style="color: #4d4950; font-weight: 400">{{
                        LeadDetail.phoneNumber
                      }}</span>
                    </p>
                  </div>
                  <div class="col-12" v-if="LeadDetail.email">
                    <p
                      class=""
                      style="font-weight: 600; font-size: 16px; color: #4d4950"
                    >
                      Email:
                      <span style="color: #4d4950; font-weight: 400">{{
                        LeadDetail.email
                      }}</span>
                    </p>
                  </div>
                  <div
                    class="col-12"
                    v-if="LeadDetail.files && LeadDetail.files.length"
                  >
                    <p
                      class=""
                      style="font-weight: 600; font-size: 16px; color: #4d4950"
                    >
                      Files Uploaded:
                    </p>
                    <div
                      v-for="(file, Index) in LeadDetail.files"
                      :key="Index"
                      class="d-flex align-items-center mb-2"
                    >
                      <i class="fa fa-caret-right mr-2" aria-hidden="true"></i>

                      <div class="d-flex align-items-center w-100">

                        <div class="d-flex " style="flex:0.8">
                           <p  class="ml-2 mb-0">File {{ Index + 1 }}</p>
                        </div>

                        <div class="d-flex" style="flex:0.2">

                          <button v-b-tooltip.hover title="Preview File" class="btn" @click="openFile(file)">
                            <b-icon-eye-fill></b-icon-eye-fill>
                          </button>
                         
                          <button class="btn" v-b-tooltip.hover title="Download File"  @click="downloadFile(file)">
                            <b-icon-cloud-download></b-icon-cloud-download>
                          </button>
                        </div>

                      </div>
                     
                    </div>
                  </div>
                  <div class="col-12" v-if="LeadDetail.date">
                    <p
                      class=""
                      style="font-weight: 600; font-size: 16px; color: #4d4950"
                    >
                      Date:
                      <span style="color: #4d4950; font-weight: 400">{{
                        LeadDetail.date
                      }}</span>
                    </p>
                  </div>
                  <div class="col-12" v-if="LeadDetail.freeTextNote">
                    <div class="w-100" v-if="checkIsArrayOrString(LeadDetail.freeTextNote) && checkIsArrayOrString(LeadDetail.freeTextNote) == 'array'">

                       <p
                      class=""
                      style="font-weight: 600; font-size: 16px; color: #4d4950"
                    >
                      Free Text:
                     
                    </p>

                        <div
                      v-for="(freeText, Index) in  ParseArray(LeadDetail.freeTextNote)"
                      :key="Index"
                      class="d-flex align-items-center mb-2"
                    >
                      <i class="fa fa-caret-right mr-2" aria-hidden="true"></i>

                      <div class="d-flex align-items-center w-100">

                        <div class="d-flex ">
                           <p  class="ml-2 mb-0">{{freeText.fieldValue}}</p>
                        </div>

                        

                      </div>
                     
                    </div>

                    </div>
                    <div class="w-100" v-else>
                      <p
                      class=""
                      style="font-weight: 600; font-size: 16px; color: #4d4950"
                    >
                      Free Text:
                      <span style="color: #4d4950; font-weight: 400">{{
                        LeadDetail.freeTextNote
                      }}</span>
                    </p>
                    </div>
                    
                  </div>
                  <div class="col-12" v-if="LeadDetail.total_score">
                    <p
                      class=""
                      style="font-weight: 600; font-size: 16px; color: #4d4950"
                    >
                      Total Quiz Score:
                      <span style="color: #4d4950; font-weight: 400">{{
                        LeadDetail.total_score
                      }}</span>
                    </p>
                  </div>
                  <div class="col-12" v-if="LeadDetail.address1">
                    <p
                      class=""
                      style="font-weight: 600; font-size: 16px; color: #4d4950"
                    >
                      Address:
                      <span style="color: #4d4950; font-weight: 400">{{
                        LeadDetail.address1
                      }}</span>
                    </p>
                  </div>
                  <div class="col-12" v-if="LeadDetail.address2">
                    <p
                      class=""
                      style="font-weight: 600; font-size: 16px; color: #4d4950"
                    >
                      Address 2:
                      <span style="color: #4d4950; font-weight: 400">{{
                        LeadDetail.address2
                      }}</span>
                    </p>
                  </div>
                  <div class="col-12" v-if="LeadDetail.city">
                    <p
                      class=""
                      style="font-weight: 600; font-size: 16px; color: #4d4950"
                    >
                      City:
                      <span style="color: #4d4950; font-weight: 400">{{
                        LeadDetail.city
                      }}</span>
                    </p>
                  </div>
                  <div class="col-12" v-if="LeadDetail.state">
                    <p
                      class=""
                      style="font-weight: 600; font-size: 16px; color: #4d4950"
                    >
                      State:
                      <span style="color: #4d4950; font-weight: 400">{{
                        LeadDetail.state
                      }}</span>
                    </p>
                  </div>
                  <div class="col-12" v-if="LeadDetail.zip_code">
                    <p
                      class=""
                      style="font-weight: 600; font-size: 16px; color: #4d4950"
                    >
                      Zip Code:
                      <span style="color: #4d4950; font-weight: 400">{{
                        LeadDetail.zip_code
                      }}</span>
                    </p>
                  </div>
                  <div class="col-12" v-if="LeadDetail.country">
                    <p
                      class=""
                      style="font-weight: 600; font-size: 16px; color: #4d4950"
                    >
                      Country:
                      <span style="color: #4d4950; font-weight: 400">{{
                        LeadDetail.country
                      }}</span>
                    </p>
                  </div>
                  <div class="col-12" v-if="LeadDetail.organisation">
                    <p
                      class=""
                      style="font-weight: 600; font-size: 16px; color: #4d4950"
                    >
                      Organization:
                      <span style="color: #4d4950; font-weight: 400">{{
                        LeadDetail.organisation
                      }}</span>
                    </p>
                  </div>
                  <div class="col-12" v-if="LeadDetail.website">
                    <p
                      class=""
                      style="font-weight: 600; font-size: 16px; color: #4d4950"
                    >
                      Website:
                      <span style="color: #4d4950; font-weight: 400">{{
                        LeadDetail.website
                      }}</span>
                    </p>
                  </div>
                </div>
                <!-- Quiz Ans start     -->
                <div class="row">
                  <div class="col-12">
                    <p
                      class=""
                      style="font-weight: 600; font-size: 18px; color: #292929"
                    >
                      Quizz answers:
                    </p>
                  </div>
                  <div class="col-12">
                    <div
                      class="py-2"
                      style="
                        min-height: 50px;
                        height: 100%;
                        max-height: 250px;
                        overflow: hidden scroll;
                      "
                    >
                      <div
                        class="mb-3"
                        v-for="(question, index) in LeadDetail.questionAnswers"
                        :key="index"
                      >
                        <div class="">
                          <div class="d-flex align-items-start w-100">
                            <span
                              style="
                                font-size: 16px;
                                color: #292929;
                                font-weight: 400;
                              "
                              class="mr-3"
                              >Q.{{ index + 1 }}</span
                            >
                            <div class="">
                              <p
                                class="m-0"
                                style="
                                  font-size: 17px;
                                  color: #292929;
                                  font-weight: 400;
                                "
                              >
                                {{ question.question }}
                              </p>
                            </div>
                          </div>

                          <div class="mt-2 pl-4">
                            <div
                              v-for="(ans, index2) in question.options"
                              :key="index2"
                              class="d-flex align-items-center mb-2"
                            >
                              <i
                                class="fa fa-caret-right mr-2"
                                aria-hidden="true"
                              ></i>

                              <div>
                                <div v-if="ans.image">
                                  <LazyImage
                                    :src="ImageSRC(ans.image)"
                                    alt=""
                                    class="img-thumbnail"
                                    style="
                                      height: 50px;
                                      width: 50px;
                                      border-radius: 50%;
                                      object-fit: cover;
                                    "
                                  />
                                </div>
                              </div>
                              <p v-if="ans.value" class="ml-2 mb-0">
                                {{ ans.value }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 mt-2">
                    <h5 class="py-3" style="color: #18191c; font-weight: 600">
                      Suggested products:
                    </h5>
                    <div class="w-100 QuestionsDiv p-0">
                      <p
                        class="mt-2 text-center"
                        v-if="
                          Object.keys(LeadDetail).length > 0 &&
                          !LeadDetail.products.length
                        "
                      >
                        No products found.
                      </p>
                      <div
                        class="p-0"
                        v-if="
                          Object.keys(LeadDetail).length > 0 &&
                          Array.isArray(LeadDetail.products) &&
                          LeadDetail.products.length
                        "
                        style="
                          min-height: 50px;
                          height: 100%;
                          max-height: 250px;
                          overflow: hidden scroll;
                        "
                      >
                        <div>
                          <div
                            v-for="(product, index) in LeadDetail.products"
                            :key="index"
                            class="w-100 px-3 singleProduct py-1 mt-2 mb-3 d-flex align-items-center"
                          >
                            <div
                              class="d-flex align-items-center w-100"
                              v-if="index < 10"
                            >
                              <div
                                class="Order mt-n1 mr-3"
                                :class="index < 1 ? 'firstProduct' : ''"
                                style="flex: 0.04; text-align: center"
                              >
                                <p class="font-weight-bold">{{ index + 1 }}</p>
                              </div>

                              <div
                                class="d-flex align-items-center ml-2"
                                style="flex: 0.9"
                              >
                                <div style="flex: 0.1" class="">
                                  <div v-if="product.image">
                                    <img
                                      v-if="product.image.startsWith('upload')"
                                      :src="'/' + product.image"
                                      alt=""
                                      class="img-thumbnail mr-2"
                                      style="
                                        height: 50px;
                                        width: 50px;
                                        border-radius: 50%;
                                        object-fit: cover;
                                      "
                                    />
                                    <img
                                      v-else
                                      :src="product.image"
                                      alt=""
                                      class="img-thumbnail mr-2"
                                      style="
                                        height: 50px;
                                        width: 50px;
                                        border-radius: 50%;
                                        object-fit: cover;
                                      "
                                    />
                                  </div>
                                  <div v-else>
                                    <img
                                      src="/images/image_default_placeholder.png"
                                      alt=""
                                      class="img-thumbnail mr-2"
                                      style="
                                        height: 50px;
                                        width: 50px;
                                        border-radius: 50%;
                                        object-fit: cover;
                                      "
                                    />
                                  </div>
                                </div>

                                <div class="d-flex" style="flex: 0.9">
                                  <p
                                    class="text-left ques mt-2"
                                    style="flex: 0.8"
                                  >
                                    {{ product.title }}
                                  </p>
                                  <p class="text-left ques mt-2">
                                    {{ getCurrency }} {{ product.price }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- <div class="py-2"  v-else>
                  <p class="">No Suggested Products</p>
               </div> -->
                    </div>
                  </div>
                </div>
                <!-- Quiz Ans end     -->
              </div>
            </div>
            <!-- End -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoaderComp from "../../customize/component/LoaderComp.vue";
import axios from "axios";
import { mapGetters } from "vuex";
import $ from "jquery";
export default {
  components: {
    LoaderComp,
  },
  data() {
    return {
      isLeadLoading: false,
      LeadData: "",
      LeadDetail: {},
    };
  },
  computed: {
    ...mapGetters(["getQuizID", "getEditorSettings"]),
    getCurrency() {
      return this.getEditorSettings.selectedCurrency.sign;
    },
  },
  methods: {
    openFile(file){
      if(file){
        window.open(file.path,"_blank")
      }
    },
   async downloadFile(file){
      if(file){
         try {
           this.$toasted.show("File preparing to download", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
        const response = await fetch(file.path);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'download-file';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      } catch (error) {
       
       this.$toasted.show("Error occured while downloading file.", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
        throw(error)
      }
      }
    },
    
    OpenModal(value) {
      this.LeadData = value;
      $("#SeeResultModal").modal("show");
    },
    async getProductData() {
      this.isLeadLoading = true;
      try {
        const id = this.LeadData.id;
        let data = {
          quizId: this.getQuizID,
        };
        const response = await axios.get(`/getLeadResultHistory/${id}`, {
          params: data,
        });
        if (response.status == 200 && response.data.status == "success") {
          this.LeadDetail = response.data.data;
        }
      } catch (error) {
        this.$toasted.show("Error occured", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
        throw error;
      } finally {
        this.isLeadLoading = false;
      }
    },
    CloseModal() {
      $("#SeeResultModal").modal("hide");
    },
    ImageSRC(image) {
      if (image.indexOf("http://") === 0 || image.indexOf("https://") === 0) {
        return image;
      } else {
        let dbImage;
        if (image.startsWith("/")) {
          dbImage = image.substring(1);
        } else {
          dbImage = image;
        }
        const baseURL = process.env.VUE_APP_API_BASE_URL;
        // return baseURL + image
        return baseURL + dbImage;
      }
    },
    checkIsArrayOrString(value){
       let parsedValue;
       if (typeof value === 'string') {
    try {
      parsedValue = JSON.parse(value);
      if (Array.isArray(parsedValue)) {
        return 'array';
      }
    } catch (error) {
      // It is a normal string
      return 'string';
    }
  }

  return null; 
    },
    ParseArray(arr){
       let parsedArray;
  
  try {
    parsedArray = JSON.parse(arr);
  } catch (error) {
    parsedArray = arr;
  }
  
  return parsedArray;
    }
  },
  mounted() {
    $(this.$refs.resultModal).on("show.bs.modal", this.getProductData);
  },
};
</script>

<style>
#resultModalContent {
  border-radius: 20px;
}
/* .QuestionsDiv{
    height:100%;
    
} */

.singleProduct .Order {
  background: #b3afb6;
  width: 25px !important;
  height: 20px;
  border-radius: 2px;
  color: #ffffff;
}
.singleProduct:hover {
  cursor: pointer;
}

.firstProduct {
  background: #ffa201 !important;
}
</style>
